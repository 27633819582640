<template>
    <div class="divination background-grad">
        <HeaderSub />
        <div class="background-grad-base">
            <section>
                <h1 class="page-title "><span>フリースタイル</span></h1>
                <div class="cardchoice-area">
                    
                    <!-- コンテナ全体でマウス・タッチイベントを拾う -->
                    <div id="card-container"
                        @mousemove="updatePointer" 
                        @touchmove.prevent="updateTouch" 
                        @click="dropCard" 
                        @touchend.prevent="dropCard"
                        style="position: relative; width: 100%; height: 100vh;">
    
                    <!-- 既に配置済みのカード一覧 -->
                    <div v-for="(card, index) in cards" 
                        :key="index"
                        class="card dropped"
                        :style="{
                        position: 'absolute',
                        left: card.x + 'px',
                        top: card.y + 'px'
                    }">
                        <img :src="card.imgUrl"
                        :style="card.cardReserve" 
                        alt="タロットカード"
                        style="width: 70px; filter: drop-shadow(0 2px 3px rgba(0, 0, 0, .2));">
                        <!--<p>{{ card.cardName }}</p>-->
                    </div>
    
                    <!-- マウスポインタ（またはタッチ位置）に追従するカード -->
                    <div v-if="currentCard" 
                        class="card current"
                        :style="{
                        position: 'absolute',
                        left: currentCard.x + 'px',
                        top: currentCard.y + 'px'
                    }">
                        <img :src="currentCard.imgUrl" alt="カード裏面" style="width: 70px; filter: drop-shadow(0 2px 3px rgba(0, 0, 0, .2));">
                    </div>
                </div>
            </div>
        </section>
        </div>
    </div>
</template>

<script>
import HeaderSub from '../components/HeaderSub'
    export default {
        components: { HeaderSub },
        data(){
            return {
                // 画像サイズ（幅、高さ）が100pxの場合の半分のサイズ
                cardWidth: 70,
                cardHeight: 119,
                // 配置済みカードの情報を保持する配列
                cards: [],
                // ランダム選択の元になるカード群
                // 例として各カードオブジェクトは以下のような構造を想定しています
                // 追従中のカード（初期状態は裏面）
                currentCard: {
                    x: 0,
                    y: 0,
                    imgUrl: require('@/assets/tarotcard_back.png'),
                    cardReserve: 'transform:rotate(0deg)'
                },
                // ランダム選択用のカードデータ（各カードは表面の画像やテキスト・名前などを保持）
                imgsCard: [
                        {
                            imgUrl: require('@/assets/tarotcard_00.png'),
                            ResultNormal: { '過去': '正位置の結果1' },
                            ResultReserve: { '過去': '逆位置の結果1' },
                            name: { normal: 'カード名1(正)', reserve: 'カード名1(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_01.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_02.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_03.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_04.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_05.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_06.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_07.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_08.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_09.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_10.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_11.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_12.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_13.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_14.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_15.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_16.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_17.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_18.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_19.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_20.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_21.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w1.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w2.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w3.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w4.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w5.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w6.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w7.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w8.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w9.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w10.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w11.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w12.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w13.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_w14.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p1.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p2.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p3.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p4.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p5.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p6.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p7.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p8.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p9.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p10.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p11.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p12.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p13.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_p14.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s1.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s2.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s3.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s4.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s5.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s6.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s7.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s8.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s9.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s10.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s11.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s12.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s13.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_s14.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c1.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c2.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c3.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c4.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c5.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c6.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c7.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c8.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c9.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c10.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c11.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c12.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c13.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                        {
                            imgUrl: require('@/assets/tarotcard_c14.png'),
                            ResultNormal: { '過去': '正位置の結果2' },
                            ResultReserve: { '過去': '逆位置の結果2' },
                            name: { normal: 'カード名2(正)', reserve: 'カード名2(逆)' }
                        },
                    ],
                }
            },
            methods:{
                // マウスが動いたときの処理
                updatePointer(e) {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // 画像の中心に合わせるため、cardWidth/2 と cardHeight/2 を引く
                    this.currentCard.x = e.clientX - rect.left - this.cardWidth / 2;
                    this.currentCard.y = e.clientY - rect.top - this.cardHeight / 2;
                },
                updateTouch(e) {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const touch = e.touches[0];
                    this.currentCard.x = touch.clientX - rect.left - this.cardWidth / 2;
                    this.currentCard.y = touch.clientY - rect.top - this.cardHeight / 2;
                },
                dropCard() {
                    if (this.imgsCard.length === 0) {
                    alert('すべてのカードは使用済みです。');
                    return;
                }
                const imageNo = Math.floor(Math.random() * this.imgsCard.length);
                const selectedCard = this.imgsCard[imageNo];
      
                const n = Math.floor(Math.random() * 2);
                let cardReserve = '';
                let cardText = '';
                let cardName = '';
      
                if (n === 1) {
                    cardReserve = 'transform:rotate(0deg)';
                    cardText = selectedCard.ResultNormal['過去'];
                    cardName = selectedCard.name.normal;
                } else {
                    cardReserve = 'transform:rotate(180deg)';
                    cardText = selectedCard.ResultReserve['過去'];
                    cardName = selectedCard.name.reserve;
                }
      
                // 使用済みカードは削除
                this.imgsCard.splice(imageNo, 1);
      
                // ドロップ位置も中心に合わせた状態で保存
                const droppedCard = {
                    x: this.currentCard.x,
                    y: this.currentCard.y,
                    imgUrl: selectedCard.imgUrl,
                    cardReserve: cardReserve,
                    cardText: cardText,
                    cardName: cardName
                };
      
                this.cards.push(droppedCard);
      
                // ドロップ後、追従カードは裏面画像にリセット
                this.currentCard.imgUrl = require('@/assets/tarotcard_back.png');
                this.currentCard.cardReserve = 'transform:rotate(0deg)';
            },
        }
    }  
</script>

<style scoped>
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    button{
        background-color: transparent;
    }
    .page-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 1.7;
        background: #ffffff73;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 12px;
        max-width: 600px;
        margin: 0 auto;
    }
  .divination {
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
  .cardchoice-area {
    margin-bottom: 20px;
  }
.card-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 20px auto 60px;
}
.card-list li {
    position: absolute;
    height: 3rem;
    margin: auto;
}
.card-list li:nth-of-type(1) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
}
.card-list li:nth-of-type(2) {
    top: 0;
    bottom: 0;
    left: 0.3rem;
}
.card-list li:nth-of-type(3) {
    top: 6rem;
    bottom: 0;
    left: 3.2rem;
}
.card-list li:nth-of-type(4) {
    top: 11rem;
    bottom: 0;
    left: 6rem;
}
.card-list li:nth-of-type(5) {
    bottom: 0.5rem;
    left: 0;
    right: 0;
    width: 20%;
}
.card-list li:nth-of-type(6) {
    top: 11rem;
    bottom: 0;
    right: 6rem;
}
.card-list li:nth-of-type(7) {
    top: 6rem;
    bottom: 0;
    right: 3.2rem;
}
.card-list li:nth-of-type(8) {
    top: 0;
    bottom: 0;
    right: 0.3rem;
}
.card-list li:nth-of-type(9) {
    top: -6rem;
    bottom: 0;
    right: 3.2rem;
}
.card-list li:nth-of-type(10) {
    top: -11rem;
    bottom: 0;
    right: 6rem;
}
.card-list li:nth-of-type(11) {
    top: 0.5rem;
    left: 0;
    right: 0;
    width: 20%;
}
.card-list li:nth-of-type(12) {
    top: -11rem;
    bottom: 0;
    left: 6rem;
}
.card-list li:nth-of-type(13) {
    top: -6rem;
    bottom: 0;
    left: 3.2rem;
}
    .card-list img{
        width: 40px;
        height: 70px;
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
    }
    .card-list p {
        font-size: 12px;
        line-height: 1.5;
    }
    .card-result {
        display: none;
    }
    .card-result__item {
        display: none;
    }
    .card-result__item1 {
        display: none;
    }
    .card-result li {
        margin-bottom: 16px;
    }
    .card-result dl {
        background: #fff;
        border-radius: 16px;
        padding: 20px;
        max-width: 400px;
        margin: 0 auto;
    }
    .card-result dl dt {
        background: #F4F4F4;
        border-radius: 100px;
        font-weight: bold;
        line-height: 1.7;
        letter-spacing: 2px;
        padding: 6px;
        max-width: 300px;
        margin: 0 auto 20px;
    }
    .card-result__card-name {
        font-weight: bold;
    }
    .card-result dl dd img {
        max-width: 100px;
    }
    @media screen and (min-width:769px) {
        .card-list img {

        }
    }
.mb-1em{
  margin-bottom: 1em;
}
.normal-txt {
    font-size: 14px;
    text-align: left;
}
.snackshow {
    display: none;
}
.resultshow {
  display: block;
}
.resultshow1,
.resultshow2,
.resultshow3,
.resultshow4,
.resultshow5,
.resultshow6,
.resultshow7,
.resultshow8,
.resultshow9,
.resultshow10,
.resultshow11,
.resultshow12,
.resultshow13 {
    display: block;
}
a,
a:visited{
    color: #fff;
}
button:disabled{
    background: #D3D3D3;
}
button:disabled a {
    color: #838383;
}
button a {
    display: inline-block;
    width: 100%;
}

.card.dropped {
  animation: pop 0.5s ease-out;
}

@keyframes pop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.card.current {
  pointer-events: none;
}
</style>
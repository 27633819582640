<template>
    <div class="divination background-grad">
        <HeaderSub />
        <div class="background-grad-base">
            <section>
                <h1 class="page-title "><span>スリーカード</span><br>「<span class="key-color-purple">{{ theme }}</span>」
                </h1>

                <div class="cardchoice-area">
                    <ul class="card-list">
                        <li>
                            <button v-bind:class="{carddisabled: cardDisabled}">
                                <img v-bind:src="imgsUrl1" v-bind:style="cardReserve1" alt="過去" v-on:click.once="ramdam1($event),change1($event),btnActive()">
                            </button>
                            <p>{{ itemsName[0]['name'] }}</p>
                        </li>
                        <li>
                            <button v-bind:class="{carddisabled: cardDisabled}">
                                <img v-bind:src="imgsUrl2" v-bind:style="cardReserve2" alt="現在" v-on:click.once="ramdam2($event),change2($event),btnActive()">
                            </button>
                            <p>{{ itemsName[1]['name'] }}</p>
                        </li>
                        <li>
                            <button v-bind:class="{carddisabled: cardDisabled}">
                                <img v-bind:src="imgsUrl3" v-bind:style="cardReserve3" alt="未来" v-on:click.once="ramdam3($event),change3($event),btnActive()">
                            </button>
                            <p>{{ itemsName[2]['name'] }}</p>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
    <div id="cardresult" class="mordal-area anchor" v-bind:class ={resultshow:isActive}>
        <h2 class="result-title" >占い結果</h2>
        <ul class="card-result mordal-area__item">
            <li v-bind:class ={resultshow1:CardIsActive1} class="card-result__item1">
                <dl>
                    <dt>{{ itemsName[0]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li class="card-result__img-area">
                                <img v-bind:src="imgsUrl1" v-bind:style="cardReserve1" alt="過去">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName1 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText1 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow1:CardIsActive1} class="card-result__item1">
                <dl>
                    <dt>{{ itemsName[1]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li class="card-result__img-area">
                                <img v-bind:src="imgsUrl2" v-bind:style="cardReserve2" alt="現在">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName2 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText2 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
            <li v-bind:class ={resultshow1:CardIsActive1} class="card-result__item1">
                <dl>
                    <dt>{{ itemsName[2]['name'] }}</dt>
                    <dd>
                        <ul>
                            <li class="card-result__img-area">
                                <img v-bind:src="imgsUrl3" v-bind:style="cardReserve3" alt="未来">
                            </li>
                            <li class="card-result__card-name">
                                {{ cardName3 }}
                            </li>
                            <li>
                                <p class="normal-txt">{{ cardText3 }}</p>
                            </li>
                        </ul>
                    </dd>
                </dl>
            </li>
        </ul>

        <div class="btn-wrap">
            <p class="btn-primary">
            <!--<router-link to="/divination-three" class="btn-primary__txt">もう一度占う</router-link>-->
            <button v-on:click="reset()">
                <a href="#"><span class="btn-primary__txt">もう一度占う</span></a>
            </button>
        </p>
        </div>
    </div>
    <div class="popup-under" v-bind:class ={popupshow:PopUpShow}>
                <div v-if="isLoggedIn">
                    <div class="form">
                        <label for="theme-select">テーマ</label>
                        <select name="theme" id="theme-select" required v-on:change="select" v-bind:disabled="selectDisabled">
                            <option value="" selected>テーマを選択</option>
                            <option value="恋愛運">恋愛運</option>
                            <option value="仕事運">仕事運</option>
                            <option value="金運">金運</option>
                        </select>
                    </div>
            
                    <button v-on:click="change" v-bind:class ={btnshow:btnShow} class="btn-primary width-100p">
                        <a href="#cardresult"><span class="btn-primary__txt">占い結果を見る</span></a>
                    </button>
                    <p v-bind:class ={snackshow:SnackIsActive} class="snackbar">
                        <img src="../assets/attention.svg">
                        {{ snacktext }}
                    </p>
                </div>
                <div v-else class="guest">
                    <p class="guest__message">{{ message }}</p>
                    <p class="btn-primary">
                        <router-link to="/login" class="btn-primary__txt">会員登録(無料)/ログイン</router-link>
                    </p>
                </div>
    </div>
</template>

<script>
import HeaderSub from '../components/HeaderSub'
    export default {
        components: { HeaderSub },

        computed: {
        isLoggedIn() {
            return Boolean(this.email)
        }
        },

        data(){
            return {
                PopUpShow: "",
                theme: "テーマ",
                selectDisabled: false,
                snacktext: 'テーマを選択してください',
                cardDisabled: 'true',
                message: "ご利用には会員登録が必要です",
                email: window.localStorage.getItem('uid'),
                turn: 0,
                isActive: false,
                isDisabled: true,
                btnShow: true,
                params: {
                    text: ''
                },
                SnackIsActive: false,
                CardIsActive1: false,
                CardIsActive2: false,
                CardIsActive3: false,
                CardIsActive4: false,
                CardIsActive5: false,
                CardIsActive6: false,
                CardIsActive7: false,
                CardIsActive8: false,
                CardIsActive9: false,
                CardIsActive10: false,
                CardIsActive11: false,
                CardIsActive12: false,
                CardIsActive13: false,
                itemsName: [
                    {
                        no: 0,
                        name: '過去',
                    },
                    {
                        no: 1,
                        name: '現在',
                    },
                    {
                        no: 2,
                        name: '未来',
                    }
                ],
                cardReserve1: '',
                cardReserve2: '',
                cardReserve3: '',
                cardText1: 'カードを開いていないため結果はありません',
                cardText2: 'カードを開いていないため結果はありません',
                cardText3: 'カードを開いていないため結果はありません',
                imgsUrl1: require('@/assets/tarotcard_back.png'),
                imgsUrl2: require('@/assets/tarotcard_back.png'),
                imgsUrl3: require('@/assets/tarotcard_back.png'),
                imgsCard: [
                    {
                            no: 0,
                            imgUrl: require('@/assets/tarotcard_00.png'),
                            name: {
                                normal: '愚者（正位置）',
                                reserve: '愚者（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたは未知の可能性を前向きに、楽観的に捉えています。新しい環境に挑戦すると良いでしょう。結果までは保証されませんが、チャレンジする価値は大いにあります。',
                                '現在':'これまでの自分の殻を脱ぎ捨て、新たな自分に出会えそうです。',
                                '未来':'新しい視点のお金の稼ぎ方や自分の能力、価値観を見つけられるかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'かなり無謀な賭けをしそうです。もしかしてまだ準備不足かもしれません。一度現実的なアプローチを考えてみると良いでしょう。',
                                '現在':'あなたは自分の方向性を見失っているかもしれません。それだけ自分に無茶な期待をしているようです。一度時間をおいて冷静になると良いでしょう。',
                                '未来':'あなたは無謀なお金の使い方や稼ぎ方を考えていそうです。もしくはあまり現実が見れていません。',
                            },
                    },
                    {
                            no: 1,
                            imgUrl: require('@/assets/tarotcard_01.png'),
                            name: {
                                normal: '魔術師（正位置）',
                                reserve: '魔術師（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたのユニークな個性を活かして新しい一歩を踏み出せそうです。今日は積極的に発言や行動をすると周りから注目を集めそうです。',
                                '現在':'自分らしさに自信を持てるでしょう。積極的に自分をアピールすると良さそうです。',
                                '未来':'新しいお金稼ぎをするための土台が整ったようです。また才能開花の予感。思い浮かぶ限りで新しい手法を試してみてください。',
                            },
                            ResultReserve: {
                                '過去':'あなたの個性がなかな活かしきれないかもしれません。自信過剰か自身がなさすぎるか、とにかく表立った発言は控えた方が良さそうです。',
                                '現在':'自分らしくいられなかったり、過剰に自分をアピールしたりするかもしれませんが、やりすぎには気をつけましょう。',
                                '未来':'まだ準備が整っていないようです。まずはやるべきことに集中しましょう。',
                            },
                    },
                    {
                            no: 2,
                            imgUrl: require('@/assets/tarotcard_02.png'),
                            name: {
                                normal: '女教皇（正位置）',
                                reserve: '女教皇（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'全ての答えはあなたの中にあります。心を研ぎ澄まして自分の直感を信じてみて。今のあなたなら冷静に見極めることができるでしょう。',
                                '現在':'自分自身を冷静に捉えられています。人から見ると凛とした雰囲気に映るでしょう。',
                                '未来':'必要な能力やお金の使い道を冷静に見極められています。また、あなたの中に眠る潜在能力に気づくかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'感情的になり冷静な判断が出来なさそうです。今日は何かを決断したり、考えすぎることをやめた方が良さそう。',
                                '現在':'感情がぶれやすヒステリックになりやすいので、感情的になったら一度一呼吸おきましょう。',
                                '未来': 'イライラして散財する可能性があります。本当に必要なもの何かを冷静に判断しましょう。',
                            },
                    },
                    {
                            no: 3,
                            imgUrl: require('@/assets/tarotcard_03.png'),
                            name: {
                                normal: '女帝（正位置）',
                                reserve: '女帝（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'豊かさを享受しそうです。何かが実ることになるので、それを大いに受け取りましょう。あなたの包容力が魅力として発揮できそうです。',
                                '現在':'あなたはとてもおおらかでゆとりがあり魅力的に見えるでしょう。優しい気持ちであるがままにいられます。',
                                '未来':'臨時収入に恵まれそうです。',
                            },
                            ResultReserve: {
                                '過去':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '現在':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                                '未来':'あれもこれもと欲張りな状況になりそうです。わがままな対応には気をつけましょう。',
                            },
                    },
                    {
                            no: 4,
                            imgUrl: require('@/assets/tarotcard_04.png'),
                            name: {
                                normal: '皇帝（正位置）',
                                reserve: '皇帝（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'地位のある人に出会えたり、自分がワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                '現在':'ワンランク上の立場になりそう。リーダーシップを発揮できるでしょう。',
                                '未来':'お金にまつわる相談は地位のあるに相談すると良い結果になるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'地位のある人からの反対に合ったり、自分が安定しない立場になりそうです。',
                                '現在':'地位のある人からの反対に合ったり、自分が安定ない立場になりそうです。',
                                '未来':'金運が安定しません。計画性を持った運用が大事です。',
                            },
                    },
                    {
                            no: 5,
                            imgUrl: require('@/assets/tarotcard_05.png'),
                            name: {
                                normal: '教皇（正位置）',
                                reserve: '教皇（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。モラルやルールを守り、道徳心のある行動ができそうです。',
                                '現在':'良い教師に出会えそうです。もしくは自分が教える立場になるかもしれません。モラルやルールを守り、道徳心のある行動ができそうです。',
                                '未来':'教皇（正位置）の「お金」結果が入ります',
                            },
                            ResultReserve: {
                                '過去':'自分の決めたルールに誰かを従わせたり、モラルのない環境になりそうです。',
                                '現在':'自分の決めたルールに誰かを従わせたり、モラルのない環境になりそうです。',
                                '未来':'モラルのないお金稼ぎを考えたりするかもしれません。',
                            },
                    },
                    {
                            no: 6,
                            imgUrl: require('@/assets/tarotcard_06.png'),
                            name: {
                                normal: '恋人（正位置）',
                                reserve: '恋人（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '現在':'運命的な出会いがありそう。自分のインスピレーションを信じましょう。何かが成就する予感がします。',
                                '未来':'直感を頼りにすると臨時収入が期待できそうです。ビビッとした感覚を大事にしましょう。',
                            },
                            ResultReserve: {
                                '過去':'契約ごとは結べない可能性が高いです。がっかりするかもしれませんが、ご縁がなかったのかもしれません。',
                                '現在':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                                '未来':'自分の直感を信じても期待外れの結果になりそう。ご縁がなかったのかもしれません。',
                            },
                    },
                    {
                            no: 7,
                            imgUrl: require('@/assets/tarotcard_07.png'),
                            name: {
                                normal: '戦車（正位置）',
                                reserve: '戦車（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'何か迷っていたりやりたいことがあるのなら迷わず進みましょう、GOサインが出ています。とにかくやってみることが大事なのです。',
                                '現在':'自分を信じて突き進んでみて。全速前進でいきましょう。',
                                '未来':'自分を信じて突き進んでみて。全速前進でいきましょう。',
                            },
                            ResultReserve: {
                                '過去':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '現在':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                                '未来':'やる気が空回りして自分を見失っています。何かを迷っているのなら、焦って動かず一旦ストップした方が良さそうです。',
                            },
                    },
                    {
                            no: 8,
                            imgUrl: require('@/assets/tarotcard_08.png'),
                            name: {
                                normal: '力（正位置）',
                                reserve: '力（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '現在':'強い精神力を持てる時。あなたの忍耐力を信じて少しずつ進みましょう。そうすれば良い結果に結びつくはずです。',
                                '未来':'長期的な資産運用に向く時。今のあなたなら忍耐強くお金を稼いだり溜めたりできるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '現在':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                                '未来':'我慢強くいられず、感情的になりうまくいかないようです。焦りは禁物です。長い目を見て取り組むことが大事になります。',
                            },
                    },
                    {
                            no: 9,
                            imgUrl: require('@/assets/tarotcard_09.png'),
                            name: {
                                normal: '隠者（正位置）',
                                reserve: '隠者（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'静かな環境で自分の可能性を探しています。その考えは正しそうです。じっくりと自分に向き合うと良いでしょう。',
                                '現在':'静かな環境で自分の可能性を探しています。その考えは正しそうです。じっくりと自分に向き合うと良いでしょう。',
                                '未来':'静かな環境で可能性を探しています。その考えは正しそうです。じっくりと向き合うと良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '現在':'あなたの視野が狭すぎるようです。思い込みで進めないように注意してください。もしくは孤独な環境になっているのかもしれません。',
                                '未来':'自分の思い込みで失敗しそう。今は無駄遣いを控えましょう。',
                            },
                    },
                    {
                            no: 10,
                            imgUrl: require('@/assets/tarotcard_10.png'),
                            name: {
                                normal: '運命の輪（正位置）',
                                reserve: '運命の輪（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '現在':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                                '未来':'あなたの人生に関わる大きな幸運のチャンスが訪れています。このチャンスはなかなか回ってこないのでぜひ掴みましょう。運命が味方してくれます。',
                            },
                            ResultReserve: {
                                '過去':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '現在':'何かが空回りすやすい状況です。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                                '未来':'タイミングが噛み合っていないようです。無理に推し進めず一旦状況を見て別のタイミングを待ちましょう。',
                            },
                    },
                    {
                            no: 11,
                            imgUrl: require('@/assets/tarotcard_11.png'),
                            name: {
                                normal: '正義（正位置）',
                                reserve: '正義（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                                '現在':'冷静で正しい判断ができる日。',
                                '未来':'冷静で正しい判断ができる日。もしくは過去に行った結果に対する正しい判断が下されます。',
                            },
                            ResultReserve: {
                                '過去':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                                '現在':'冷静さがなく正しい判断ができない日。',
                                '未来':'冷静さがなく正しい判断ができない日。もしくは過去に行った結果に対する厳しい判断が下される可能性があります。',
                            },
                    },
                    {
                            no: 12,
                            imgUrl: require('@/assets/tarotcard_12.png'),
                            name: {
                                normal: '吊られた男（正位置）',
                                reserve: '吊られた男（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                '現在':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                                '未来':'今は身動きが取れない状況かもしれませんが、状況は悪くありません。頭の中は前向きなアイディアやひらめきを得ようと研ぎ澄ましています。誰かに献身的に奉仕すると良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '現在':'献身的に尽くした結果が徒労に終わる可能性が高いです。自分を犠牲にしすぎないように気をつけましょう。',
                                '未来':'吊られた男（逆位置）の「お金」結果が入ります',
                            },
                    },
                    {
                            no: 13,
                            imgUrl: require('@/assets/tarotcard_13.png'),
                            name: {
                                normal: '死神（正位置）',
                                reserve: '死神（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。ダラダラと続けるより白黒はっきりつけた方が良さそうです。',
                                '現在':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                                '未来':'あなたの転換期が来ています。何かが終了していくでしょう。それは決してネガティブなことではありません。これからの新しい未来のために終わりを告げるのです。',
                            },
                            ResultReserve: {
                                '過去':'何かが終了し、新しい展開が始まる予感がします。もし、ダラダラと続けていることがあれば迷わず蹴りをつけてください。終わらせるからこそ新しい世界が開けるのです。変化を恐れないでください。',
                                '現在':'これまでの自分がリセットされ、新しい自分に出会えるかもしれません。白黒はっきりした方が良い結果になるでしょう。',
                                '未来':'これまでの金銭感覚がリセットされ、新しいお金稼ぎの方法に出会えるかもしれません。これまでとは違うやり方の方が良い結果になるでしょう。',
                            },
                    },
                    {
                            no: 14,
                            imgUrl: require('@/assets/tarotcard_14.png'),
                            name: {
                                normal: '節制（正位置）',
                                reserve: '節制（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'秩序が保たれています。ちょうど良いバランス感覚で、何かと何かを繋げる役割を持つかもしれません。白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '現在':'白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                                '未来':'白黒つけず、グレーで絶妙な状態がキープできるでしょう。高望みをせず無理のない行動が吉となります。',
                            },
                            ResultReserve: {
                                '過去':'あなたのどちらつかずな対応が仇となるかもしれません。もしくは周りの環境に自分のバランスが保てなくなる可能性もあります。自分のペースが乱される環境は避けましょう。',
                                '現在':'あなたのどちらつかずな対応が仇となるかもしれません。また自分のペースが乱される環境は避けましょう。',
                                '未来':'あなたのどちらつかずな対応が仇となるかもしれません。また自分のペースが乱される環境は避けましょう。',
                            },
                    },
                    {
                            no: 15,
                            imgUrl: require('@/assets/tarotcard_15.png'),
                            name: {
                                normal: '悪魔（正位置）',
                                reserve: '悪魔（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'何かに執着しているかもしれません。これまでの柵に囚われています。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                '現在':'何かに執着しているかもしれません。これまでの柵に囚われています。そのまま続けるのは良くないとわかっていても辞められない状況に陥りがちなので、抜けられる強い意思を持つことが大事です。',
                                '未来':'お金にまつわることで強い執着があるようです。ぬるま湯に浸かっている状態であれば、このまま続けるのは良くないでしょう。別の方法も考えるのも一つの手です。',
                            },
                            ResultReserve: {
                                '過去':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '現在':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                                '未来':'これまでの悪い習慣や環境、考えから抜け出し、それを断ち切ろうとしています。物理的、精神的な解放が訪れるかもしれません。正しい道へ進むことができるでしょう。ただしこれからも甘い誘惑は訪れるかもしれないため、油断は禁物です。',
                            },
                    },
                    {
                            no: 16,
                            imgUrl: require('@/assets/tarotcard_16.png'),
                            name: {
                                normal: '塔（正位置）',
                                reserve: '塔（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                '現在':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                                '未来':'あなたにとって予想外で衝撃的なできことが起こるでしょう。不意なトラブルが起きる可能性があるので注意してください。ただしそれはあなたの間違った考えや環境から抜け出せるチャンスでもあるのです。',
                            },
                            ResultReserve: {
                                '過去':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '現在':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                                '未来':'ゆっくりと何かが崩れていきます。問題が長期化する可能性が高いです。間違ったやり方や環境から抜け出せるようにもがいていくかもしれません。',
                            },
                    },
                    {
                            no: 17,
                            imgUrl: require('@/assets/tarotcard_17.png'),
                            name: {
                                normal: '星（正位置）',
                                reserve: '星（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '現在':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                                '未来':'あなたは夢と希望に満ち溢れています。目標に向かって歩んでいきましょう。そうすれば夢も叶いやすくなります。',
                            },
                            ResultReserve: {
                                '過去':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '現在':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                                '未来':'理想が高すぎたり、目標達成が難しい状況にありそうです。夢のままで終わらないよう、理想を下げて実現できそうな目標設定をする方が良いでしょう。',
                            },
                    },
                    {
                            no: 18,
                            imgUrl: require('@/assets/tarotcard_18.png'),
                            name: {
                                normal: '月（正位置）',
                                reserve: '月（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '現在':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                                '未来':'もやもやして先が見えない状態です。悩みが深くなってしまいそうなので一度リラックスしましょう。',
                            },
                            ResultReserve: {
                                '過去':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '現在':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                                '未来':'悩みや問題解決の糸口が見えそうです。現実的な落とし所がわかるでしょう。',
                            },
                    },
                    {
                            no: 19,
                            imgUrl: require('@/assets/tarotcard_19.png'),
                            name: {
                                normal: '太陽（正位置）',
                                reserve: '太陽（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '現在':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                                '未来':'これまでの努力が報われ、気持ち的に大きな達成感が得られるでしょう。もしくは、公に日の目を浴びるような状況になりそうです。',
                            },
                            ResultReserve: {
                                '過去':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '現在':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                                '未来':'努力が報われず、晴々とした気持ちにはなれないかもしれません。自分を曝け出しすぎて裏目に出るか、表に出ないことを暗示しています。',
                            },
                    },
                    {
                            no: 20,
                            imgUrl: require('@/assets/tarotcard_20.png'),
                            name: {
                                normal: '審判（正位置）',
                                reserve: '審判（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れたり、復縁の可能性があります。',
                                '現在':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                                '未来':'復活のチャンスが訪れています。過去諦めたことに再度チャレンジする機会が訪れるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '現在':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                                '未来':'過去のつながりや願いは復活しない可能性が高いです。今はまだそのタイミングではないか、もしくはご縁がなかったのかもしれません。',
                            },
                    },
                    {
                            no: 21,
                            imgUrl: require('@/assets/tarotcard_21.png'),
                            name: {
                                normal: '世界（正位置）',
                                reserve: '世界（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '現在':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                                '未来':'完成の時です。自分の理想や目標を達成するでしょう。これ以上の完成はありません。そして次の新たな目標に向かって進むと良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '現在':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                                '未来':'まだ未完成の状態です。自分の理想や目標を叶えるにはまだ何かが足りていないようです。もしくは全体のバランスが悪くなっているようです。焦らずに目標に向かって努力しましょう。',
                            },
                    },
                    {
                            no: 101,
                            imgUrl: require('@/assets/tarotcard_w1.png'),
                            name: {
                                normal: 'ワンドエース',
                                reserve: 'ワンドエース（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '現在':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                                '未来':'あなたのアイディアや想いが湧き上がり、その足を一歩進めることができるでしょう。ぜひその想いを胸に目標に向かってスタートしてみてください。',
                            },
                            ResultReserve: {
                                '過去':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '現在':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                                '未来':'あなたのやる気や熱意が今一歩足りていないようです。新しい挑戦のための気持ちが落ちているので、また熱意が湧き上がるのを待つのも良いのかもしれません。',
                            },
                    },
                    {
                            no: 102,
                            imgUrl: require('@/assets/tarotcard_w2.png'),
                            name: {
                                normal: 'ワンド2',
                                reserve: 'ワンド2（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '現在':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                                '未来':'あなたの目標はまず1つ達成しそうです。ただ、もうすでにあなたはその次の目標を見据えていくでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '現在':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                                '未来':'あなたの目標を達成するのは難しいようです。計画を練り直した方が良いかもしれません。',
                            },
                    },
                    {
                            no: 103,
                            imgUrl: require('@/assets/tarotcard_w3.png'),
                            name: {
                                normal: 'ワンド3',
                                reserve: 'ワンド3（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '現在':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                                '未来':'未来の展望が明るいようです。あなたはもしかしたら想いを共にする協力者に出会えたのかもしれません。そのような協力関係を大事にすると良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '現在':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                                '未来':'あなたの目指す先が見えにくくなっています。このままではあまり良い結果は望めないかもしれません。周りとの協力体制を整えましょう。',
                            },
                    },
                    {
                            no: 104,
                            imgUrl: require('@/assets/tarotcard_w4.png'),
                            name: {
                                normal: 'ワンド4',
                                reserve: 'ワンド4（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '現在':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                                '未来':'仲間に祝福され、落ち着ける場所が見るかるでしょう。ここで一旦気楽に一休みするのも良いですね。',
                            },
                            ResultReserve: {
                                '過去':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '現在':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                                '未来':'今の周りの仲間や環境は暖かいですが、そんな状態に少しマンネリを感じるかもしれません。今ひとつ何か足りないと思うのはなぜなのか、少し考えてみても良いのかもしれませんね。',
                            },
                    },
                    {
                            no: 105,
                            imgUrl: require('@/assets/tarotcard_w5.png'),
                            name: {
                                normal: 'ワンド5',
                                reserve: 'ワンド5（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '現在':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                                '未来':'何かに葛藤しているようです。ただ深刻なものではなく、自分の中での熱意をどのように処理すれば良いのか決着がついていなかったり、人と想いを前向きにぶつけ合うような日になるかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '現在':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                                '未来':'葛藤するような出来事があり決着がつかずにモヤモヤしてしまう日かもしれません。いろんな希望や想いがあるのかもしれませんが、まずは一旦冷静になってみても良いかもしれません。人との言い合いには気をつけましょう。',
                            },
                    },
                    {
                            no: 106,
                            imgUrl: require('@/assets/tarotcard_w6.png'),
                            name: {
                                normal: 'ワンド6',
                                reserve: 'ワンド6（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '現在':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                                '未来':'周囲から称賛されるような出来事がありそうです。周りが持ち上げてくれるような状態での成功となるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '現在':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                                '未来':'自分が思うような評価を受けられず満足いかないかもしれません。もしくはあなたの成功の足を引っ張る人が現れる可能性も。',
                            },
                    },
                    {
                            no: 107,
                            imgUrl: require('@/assets/tarotcard_w7.png'),
                            name: {
                                normal: 'ワンド7',
                                reserve: 'ワンド7（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '現在':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                                '未来':'周りより一歩上の有利な立場になれるでしょう。攻めの姿勢を崩さないことでその立場をキープできそうです。',
                            },
                            ResultReserve: {
                                '過去':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '現在':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                                '未来':'有利な立場から一転して立場を追われるような状況になるかもしれません。状況をよくみて対応していきましょう。油断大敵です。',
                            },
                    },
                    {
                            no: 108,
                            imgUrl: require('@/assets/tarotcard_w8.png'),
                            name: {
                                normal: 'ワンド8',
                                reserve: 'ワンド8（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '現在':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                                '未来':'物事が急速に進んでいきます。チャンスはすぐ過ぎてしまうのでしっかりキャッチして。',
                            },
                            ResultReserve: {
                                '過去':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '現在':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                                '未来':'物事が思ったようにうまく進まないと感じられるかもしれません。焦らず次のチャンスを待っても良さそうです。',
                            },
                    },
                    {
                            no: 109,
                            imgUrl: require('@/assets/tarotcard_w9.png'),
                            name: {
                                normal: 'ワンド9',
                                reserve: 'ワンド9（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '現在':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                                '未来':'今は待ちの姿勢となるでしょう。ゴールはすぐそこなので、あと一歩踏ん張ってみましょう。',
                            },
                            ResultReserve: {
                                '過去':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '現在':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                                '未来':'自分の守っていたものが崩れてしまいそうです。一旦体制を立て直した方が良さそうです。もしくは慎重になり過ぎているのかもしれません。',
                            },
                    },
                    {
                            no: 110,
                            imgUrl: require('@/assets/tarotcard_w10.png'),
                            name: {
                                normal: 'ワンド10',
                                reserve: 'ワンド10（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '現在':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                                '未来':'夢や理想、責任を背負い過ぎているようです。ただしその強い責任感であなたは最後までやり遂げるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '現在':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                                '未来':'義務や責任を放棄してしまいそうです。あなたには背負いきれないものだったのかもしれません。投げ出してしまう前に誰かに相談してみましょう。',
                            },
                    },
                    {
                            no: 111,
                            imgUrl: require('@/assets/tarotcard_w11.png'),
                            name: {
                                normal: 'ワンドペイジ',
                                reserve: 'ワンドペイジ（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '現在':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                                '未来':'良い知らせがありそうです。若く情熱に溢れる人がキーマンになるかもしれません。情熱を向けるものが見つかりそう。',
                            },
                            ResultReserve: {
                                '過去':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '現在':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                                '未来':'悪い知らせがありそうです。あなたの情熱を向ける方向性が見えにくくなるかもしれません。',
                            },
                    },
                    {
                            no: 112,
                            imgUrl: require('@/assets/tarotcard_w12.png'),
                            name: {
                                normal: 'ワンドナイト',
                                reserve: 'ワンドナイト（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '現在':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                                '未来':'とても勢いがあるので、何かを積極的にチャレンジしてみるとよいでしょう。情熱的なリーダーについていくとあなたを引っ張っていってくれそうです。',
                            },
                            ResultReserve: {
                                '過去':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '現在':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                                '未来':'やる気や情熱が空回りしそうです。慌てずに突っ走らないようにしましょう。周囲にいる荒々しく強引な男性には注意してください。',
                            },
                    },
                    {
                            no: 113,
                            imgUrl: require('@/assets/tarotcard_w13.png'),
                            name: {
                                normal: 'ワンドクイーン',
                                reserve: 'ワンドクイーン（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '現在':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                                '未来':'あなたの魅力が輝く時。堂々と自分らしさをアピールしましょう。困ったとき周囲の姉御肌の人に頼るとあなたを助けてくれそうです。',
                            },
                            ResultReserve: {
                                '過去':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '現在':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                                '未来':'自分に自信がなくなったり、もしくは自信過剰になりわがままな態度が問題になりそう。自分の想いに閉じずに客観的な意見を聞くのも良いかもしれません。姉御肌の人に振り回されやすいので注意しましょう。',
                            },
                    },
                    {
                            no: 114,
                            imgUrl: require('@/assets/tarotcard_w14.png'),
                            name: {
                                normal: 'ワンドキング',
                                reserve: 'ワンドキング（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '現在':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                                '未来':'実行力があり物事を一人でどんどん進めていけるでしょう。それによって周りからの信頼も厚くなりそうです。何かあったときはフリーランスや個人経営者に頼ると良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '現在':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                                '未来':'ワンマンな対応で周りを振り回したり、もしくはあなたが振り回される可能性があるので気をつけましょう。',
                            },
                    },
                    {
                            no: 201,
                            imgUrl: require('@/assets/tarotcard_p1.png'),
                            name: {
                                normal: 'ペンタクルエース',
                                reserve: 'ペンタクルエース（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '現在':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                                '未来':'物質的な成功が具体的に形になり始めるでしょう。特にビジネスチャンスの始まりが訪れます。',
                            },
                            ResultReserve: {
                                '過去':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '現在':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                                '未来':'新しく始めようとしたプランが形になりにくいようです。自分の能力が活かしきれないようなので、今は無理に進まない方が良さそうです。',
                            },
                    },
                    {
                            no: 202,
                            imgUrl: require('@/assets/tarotcard_p2.png'),
                            name: {
                                normal: 'ペンタクル2',
                                reserve: 'ペンタクル2（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '現在':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                                '未来':'2つのものをバランスよく器用に保ち、両立ができそうです。その調子で相手の立場と自分の立場をうまく調整していきましょう。',
                            },
                            ResultReserve: {
                                '過去':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '現在':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                                '未来':'2つのもののバランスを崩してしいまいそうです。どちらも大事なのかもしれませんが、今は両立することが難しかったり、どちらかを優先し過ぎているのかもしれません。コントロールが難しいのであれば一度状況を整理してみましょう。',
                            },
                    },
                    {
                            no: 203,
                            imgUrl: require('@/assets/tarotcard_p3.png'),
                            name: {
                                normal: 'ペンタクル3',
                                reserve: 'ペンタクル3（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '現在':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                                '未来':'あなたの能力が評価され認められるでしょう。特に仕事関係において協業していくこともあるかもしれません。あなたの活躍は十分期待されています。',
                            },
                            ResultReserve: {
                                '過去':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '現在':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                                '未来':'あなたが思うより周りからの評価は低くなりそうです。今は自分の経験値を磨いたり、あなたらしい個性をもっと伸ばしていくと良いでしょう。',
                            },
                    },
                    {
                            no: 204,
                            imgUrl: require('@/assets/tarotcard_p4.png'),
                            name: {
                                normal: 'ペンタクル4',
                                reserve: 'ペンタクル4（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '現在':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                                '未来':'今は守りに入っているようです。堅実に自分の財産をキープできるでしょう。ただ少し意地になっている状況かもしれません。慎重なのも良いですが、あまりケチケチし過ぎないようにしてもいいかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '現在':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                                '未来':'自分の守りを固め過ぎると状況が悪くなっていきそうです。何かに必死にしがみついたり、逆に自暴自棄になりがちなので行き過ぎには注意しましょう。',
                            },
                    },
                    {
                            no: 205,
                            imgUrl: require('@/assets/tarotcard_p5.png'),
                            name: {
                                normal: 'ペンタクル5',
                                reserve: 'ペンタクル5（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '現在':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                                '未来':'物質的な損害経済的な困窮など、悪い環境に追い詰められてしまいそうです。今は明るい未来が見えないかもしれませんが、必ず別の道もあるはずです。周囲を冷静にみてください。',
                            },
                            ResultReserve: {
                                '過去':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '現在':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                                '未来':'身も心も非常に苦しい状況になっていきそうです。もしパートナーがいるなら一緒に進むと一緒に悪い方向に行ってしまいそう。別の道や可能性を探すのも良いかもしれません。',
                            },
                    },
                    {
                            no: 206,
                            imgUrl: require('@/assets/tarotcard_p6.png'),
                            name: {
                                normal: 'ペンタクル6',
                                reserve: 'ペンタクル6（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '現在':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                                '未来':'あなたの働きが認められ、経済的、金銭的に恵まれそうです。もしくは自分が慈善的な活動をして誰かを助けるかもしれません。持ちつ持たれつの関係性が大事となるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '現在':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                                '未来':'あなたの働きが認められにくいでしょう。立場が上の人からのパワーバランスが強すぎたりして、不当な扱いを受ける可能性も。もし相手とのバランスの取れていない関係であれば無理をしないようにしましょう。',
                            },
                    },
                    {
                            no: 207,
                            imgUrl: require('@/assets/tarotcard_p7.png'),
                            name: {
                                normal: 'ペンタクル7',
                                reserve: 'ペンタクル7（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '現在':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                                '未来':'あなたはある程度の成果を手にしますが、進路や方向性について悩んでいるかもしれません。なんとなく物足りないと思うのは成長意欲があるからなので、これを機にじっくりと今後を考えるのも良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '現在':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                                '未来':'今は人生の分岐点にいるようです。次の進路へ行くべきかどうか決断が仕切れず、モヤモヤと考えてしまっているかもしれません。',
                            },
                    },
                    {
                            no: 208,
                            imgUrl: require('@/assets/tarotcard_p8.png'),
                            name: {
                                normal: 'ペンタクル8',
                                reserve: 'ペンタクル8（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '現在':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                                '未来':'あなたは技術を磨いたり目の前のやるべき課題に集中して取り組めていけそうです。その調子でコツコツと積み重ねると良い結果を生んでいくでしょう。',
                            },
                            ResultReserve: {
                                '過去':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '現在':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                                '未来':'今の状況がマンネリ化してしまい、目の前のやるべき課題に集中しきれないようです。もしそれがあなたにとって大事なことであればもう少し真剣に取り組みましょう。',
                            },
                    },
                    {
                            no: 209,
                            imgUrl: require('@/assets/tarotcard_p9.png'),
                            name: {
                                normal: 'ペンタクル9',
                                reserve: 'ペンタクル9（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '現在':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                                '未来':'あなたの努力や才能が認められ、周りから引き立てられ環境に恵まれるでしょう。出世したり金銭的に余裕が生まれそうです。',
                            },
                            ResultReserve: {
                                '過去':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '現在':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                                '未来':'恵まれた状況にあぐらをかいてしまったり、もしくは高望みをしてしまったり、支援者には恵まれにくいでしょう。自分の態度を振り返り改めた方が良いかもしれません。',
                            },
                    },
                    {
                            no: 210,
                            imgUrl: require('@/assets/tarotcard_p10.png'),
                            name: {
                                normal: 'ペンタクル10',
                                reserve: 'ペンタクル10（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '現在':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                                '未来':'何かを継承したり、環境や目の前の状況が安定していくでしょう。また家族に関する物質的な良い状況が訪れるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '現在':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                                '未来':'環境は平和で安定しているものの、状況に苛立ったり何かしらネガティブな思いがあるかもしれません。もしくは不安定になるような状況になることも。',
                            },
                    },
                    {
                            no: 211,
                            imgUrl: require('@/assets/tarotcard_p11.png'),
                            name: {
                                normal: 'ペンタクルペイジ',
                                reserve: 'ペンタクルペイジ（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '現在':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                                '未来':'あなたの計画は前向きな方向に進んでいけるでしょう。現実的で具体的な計画がより良い結果に繋がります。',
                            },
                            ResultReserve: {
                                '過去':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '現在':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                                '未来':'あなたの計画はあまり良い結果を生まないようです。現実的で具体的な計画を立てましょう。',
                            },
                    },
                    {
                            no: 212,
                            imgUrl: require('@/assets/tarotcard_p12.png'),
                            name: {
                                normal: 'ペンタクルナイト',
                                reserve: 'ペンタクルナイト（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '現在':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '未来':'あなたの堅実な姿勢が功を奏し、ゆっくりですが着実に進んでいくでしょう。結果を焦らず、長期的な目線で取り組むことが大事です。',
                            },
                            ResultReserve: {
                                '過去':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '現在':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                                '未来':'物事の進展はかなり遅くなりそうです。結果を焦らず、長期的な目線で取り組むことが大事です。',
                            },
                    },
                    {
                            no: 213,
                            imgUrl: require('@/assets/tarotcard_p13.png'),
                            name: {
                                normal: 'ペンタクルクイーン',
                                reserve: 'ペンタクルクイーン（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '現在':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                                '未来':'自分の能力を活かし、その良さが無理なく自然に広がっていけそうです。経済的にも安定し、穏やかな心を保てそうです。',
                            },
                            ResultReserve: {
                                '過去':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '現在':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                                '未来':'自分の能力に不安があったり、経済的な不安があるかもしれません。自分のペースを崩さない範囲で行動するのが良さそうです。',
                            },
                    },
                    {
                            no: 214,
                            imgUrl: require('@/assets/tarotcard_p14.png'),
                            name: {
                                normal: 'ペンタクルキング',
                                reserve: 'ペンタクルキング（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '現在':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                                '未来':'自分の能力や価値を認められるでしょう。経営者や立場が上で経験が豊富な人を頼るとバックアップしてもらえるかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '現在':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                                '未来':'経営者や立場の上の人の振る舞いで、自分の価値を認めてもらえなかったり状況が悪くなるかもしれません。',
                            },
                    },
                    {
                            no: 301,
                            imgUrl: require('@/assets/tarotcard_s1.png'),
                            name: {
                                normal: 'ソードエース',
                                reserve: 'ソードエース（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '現在':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                                '未来':'勇気ある決断ができそうです。あなたの強い覚悟と意志があれば、新しい始まりを切り開くことができるでしょう。勝利への第一歩です。',
                            },
                            ResultReserve: {
                                '過去':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '現在':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                                '未来':'覚悟をしきれないのか、新しい挑戦に進められないようです。もしうまくいく可能性が低いのであれば、無理やり進めなくて良いのかもしれません。やるなら勝ちにいくくらいの強い覚悟が必要となるでしょう。',
                            },
                    },
                    {
                            no: 302,
                            imgUrl: require('@/assets/tarotcard_s2.png'),
                            name: {
                                normal: 'ソード2',
                                reserve: 'ソード2（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '現在':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                                '未来':'今は何かを決断するべきタイミングではないようです。選択肢のどちらを選ぶべきか迷っているのなら、現在の状況のバランスは一旦保っているままの方が良さそうです。',
                            },
                            ResultReserve: {
                                '過去':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '現在':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                                '未来':'何を選択するか悩んでいるようですが、今は冷静に状況を判断できる状態ではないようです。無理に決断しないようにして、落ち着いてから考えた方が良いでしょう。',
                            },
                    },
                    {
                            no: 303,
                            imgUrl: require('@/assets/tarotcard_s3.png'),
                            name: {
                                normal: 'ソード3',
                                reserve: 'ソード3（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '現在':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '未来':'心が傷つくような状況が訪れそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                            },
                            ResultReserve: {
                                '過去':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '現在':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                                '未来':'心が傷つくような状況をひきづってしまいそうです。ただ、そうなったのは仕方がない状況だと考えられますので、一度心を休ませてください。',
                            },
                    },

                    {
                            no: 304,
                            imgUrl: require('@/assets/tarotcard_s4.png'),
                            name: {
                                normal: 'ソード4',
                                reserve: 'ソード4（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '現在':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                                '未来':'今は一旦休息の時です。準備とタイミングが来るまで自分を頭と体を休めて整えておきましょう。',
                            },
                            ResultReserve: {
                                '過去':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '現在':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                                '未来':'動くべき時が来たようです。何か寝かせていたことがあれば再開してみると良いでしょう。',
                            },
                    },

                    {
                            no: 305,
                            imgUrl: require('@/assets/tarotcard_s5.png'),
                            name: {
                                normal: 'ソード5',
                                reserve: 'ソード5（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '現在':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                                '未来':'勝敗をつけることや争いごとがあるのであればあなたの戦略勝ちとなるでしょう。ただし相手との遺恨を残す結果となりそうです。',
                            },
                            ResultReserve: {
                                '過去':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '現在':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                                '未来':'勝敗をつけることや争いごとがあるのであればあなたの負けとなりそうです。これは相手の戦略勝ちのようです。',
                            },
                    },

                    {
                            no: 306,
                            imgUrl: require('@/assets/tarotcard_s6.png'),
                            name: {
                                normal: 'ソード6',
                                reserve: 'ソード6（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '現在':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                                '未来':'あなたを手助けする援助者が現れたり、これまでの環境から移動することによってあなたの安全や発展が見込めるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '現在':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                                '未来':'今向かおうとしているとこがあるなら、思わぬ危険があるかもしれません。もしくは感情が昂りバランスを崩すことも。今は無理して環境を変えない方が良さそうです。',
                            },
                    },

                    {
                            no: 307,
                            imgUrl: require('@/assets/tarotcard_s7.png'),
                            name: {
                                normal: 'ソード7',
                                reserve: 'ソード7（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '現在':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                                '未来':'自分の考えだけで物事を進めようとしているかもしれません。ただ考えが甘かったり抜けがある可能性が高いので、もう少し人の意見に耳を傾けましょう。',
                            },
                            ResultReserve: {
                                '過去':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '現在':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                                '未来':'自分勝手な考えを改めて、あなたは人の助言や助けを受け入れられそうです。もしくはこっそり行っていた悪事が周囲にバレることもあるかもしれません。',
                            },
                    },

                    {
                            no: 308,
                            imgUrl: require('@/assets/tarotcard_s8.png'),
                            name: {
                                normal: 'ソード8',
                                reserve: 'ソード8（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '現在':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                                '未来':'自分の考えに囚われて身動きが取れなくなっているようです。あなたを縛っているのは他人や環境ではなくあなた自身です。自分の可能性を狭めないようにしましょう。',
                            },
                            ResultReserve: {
                                '過去':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '現在':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                                '未来':'出口のない迷路に閉じ込められたような状況かもしれません。ただ少し見方を変えるだけで他の道はあるはずです。',
                            },
                    },

                    {
                            no: 309,
                            imgUrl: require('@/assets/tarotcard_s9.png'),
                            name: {
                                normal: 'ソード9',
                                reserve: 'ソード9（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '現在':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                                '未来':'絶望的な状況に感じるかもしれませんが、あなたのいる環境は実はそこまで悪くはありません。あまり深刻に考えすぎないようにしましょう。',
                            },
                            ResultReserve: {
                                '過去':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '現在':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                                '未来':'絶望的だと思っていた状況から抜け出せそうです。実はあなたの状況は思うほど悪くないと気づけるのかもしれません。',
                            },
                    },

                    {
                            no: 310,
                            imgUrl: require('@/assets/tarotcard_s10.png'),
                            name: {
                                normal: 'ソード10',
                                reserve: 'ソード10（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '現在':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                                '未来':'考えつく限りの手を尽くした結果、何か物事の終焉を迎えそうです。それは終わりを迎えるしかありませんが、その先には希望の光が見え始めています。',
                            },
                            ResultReserve: {
                                '過去':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '現在':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                                '未来':'もし何かが終わりそうなのであればそれを受け入れましょう。希望はすぐそこに見えています。終わったからこそ別の新しい可能性が生まれるのです。',
                            },
                    },

                    {
                            no: 311,
                            imgUrl: require('@/assets/tarotcard_s11.png'),
                            name: {
                                normal: 'ソードペイジ',
                                reserve: 'ソードペイジ（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '現在':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                                '未来':'さまざまな情報を武器にできるでしょう。調べられることは事前に調査しておくと良いです。もしくは若い男性から有益な情報が手に入れられるかも。',
                            },
                            ResultReserve: {
                                '過去':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '現在':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                                '未来':'相手を警戒しすぎたり、もしくは隙を疲れるような状況になりそうです。状況を斜めに構えすぎているかもしれません。またスパイのような人には気をつけてください。',
                            },
                    },

                    {
                            no: 312,
                            imgUrl: require('@/assets/tarotcard_s12.png'),
                            name: {
                                normal: 'ソードナイト',
                                reserve: 'ソードナイト（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '現在':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                                '未来':'あなたのスピード感ある行動力で果敢に挑戦できるでしょう。早い決断が功を奏します。もしくは男性リーダーについていくと良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '現在':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                                '未来':'攻めの姿勢が裏目に出そうです。焦りは禁物です。まずは状況をきちんと把握しましょう。男性リーダーがいるなら振り回されそうなので注意してください。',
                            },
                    },

                    {
                            no: 313,
                            imgUrl: require('@/assets/tarotcard_s13.png'),
                            name: {
                                normal: 'ソードクイーン',
                                reserve: 'ソードクイーン（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '現在':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                                '未来':'あなたの感情を交えない冷静な判断と助言が周囲を導くでしょう。鋭い視点は周りから頼りにされます。もしくはそのような頭の切れる女性に相談すると解決に向かいそうです。',
                            },
                            ResultReserve: {
                                '過去':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '現在':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                                '未来':'あなたの批判的なものの言い方で誰かから反感を買ってしまうこともありそうです。理論ではなく相手の感情や状況を汲み取ってあげる優しさも時には必要となるでしょう。もしくは理論的な女性からのキツイ指摘を受けることもありそうです。',
                            },
                    },

                    {
                            no: 314,
                            imgUrl: require('@/assets/tarotcard_s14.png'),
                            name: {
                                normal: 'ソードキング',
                                reserve: 'ソードキング（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '現在':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                                '未来':'交渉ごとは有利に進めることができそうです。ロジカルシンキングで相手をうまく説得できることができるでしょう。頭の切れる上司との関わりもあるかもしれません。',
                            },
                            ResultReserve: {
                                '過去':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '現在':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                                '未来':'自分の理論だけで強引に進めることで周りから反感を買ったり、実質的権力者の横暴な対応で振り回されるかもしれません。',
                            },
                    },

                    {
                            no: 401,
                            imgUrl: require('@/assets/tarotcard_c1.png'),
                            name: {
                                normal: 'カップエース',
                                reserve: 'ソードエース（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '現在':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                                '未来':'あなたのピュアな思いが溢れるような出来事が訪れそうです。心が動くような始まりが期待できます。',
                            },
                            ResultReserve: {
                                '過去':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '現在':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                                '未来':'新しい初まりを期待したものの、期待外れな出来事により悲しい気持ちになり感情を揺さぶられそうです。',
                            },
                    },
                    {
                            no: 402,
                            imgUrl: require('@/assets/tarotcard_c2.png'),
                            name: {
                                normal: 'カップ2',
                                reserve: 'カップ2（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '現在':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                                '未来':'相手へ思いが通じ、相思相愛の関係になれそうです。ビジネスパートナーでは想いが共感でき、恋愛のパートナーでは両思いとなれるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '現在':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                                '未来':'相手との思いのすれ違いが起きてしまいそうです。どちらかの想いが強すぎるのか弱すぎるのか、二人のバランスが取れていないようです。',
                            },
                    },
                    {
                            no: 403,
                            imgUrl: require('@/assets/tarotcard_c3.png'),
                            name: {
                                normal: 'カップ3',
                                reserve: 'カップ3（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '現在':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                                '未来':'嬉しい出来事や成果がありそうです。仲間や協力者との楽しい関係性や宴も示されています。',
                            },
                            ResultReserve: {
                                '過去':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '現在':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                                '未来':'協力体制を組もうとしたもののそれがうまくいかなさそうです。楽なことばかり目を向けたり、お互いが馴れ合いになってしまうことが原因なのかもしれません。',
                            },
                    },
                    {
                            no: 404,
                            imgUrl: require('@/assets/tarotcard_c4.png'),
                            name: {
                                normal: 'カップ4',
                                reserve: 'カップ4（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '現在':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                                '未来':'自分の殻に閉じこもり考え込んでしまいそうです。目の前の何かにいまいち納得していないようです。本当に自分が心躍るものを見極めようとしています。',
                            },
                            ResultReserve: {
                                '過去':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '現在':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                                '未来':'あなたはしばらくあれこれと悩んでいたかもしれませんが、ようやく目の前のチャンスに気づきそれを掴むことができそうです。周りから良いオファーがもらえるかもしれません。',
                            },
                    },
                    {
                            no: 405,
                            imgUrl: require('@/assets/tarotcard_c5.png'),
                            name: {
                                normal: 'カップ5',
                                reserve: 'カップ5（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '現在':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                                '未来':'期待していたことが叶わず、あなたはがっかりしてしまうかもしれません。ただ、他に目を向けると別の可能性が残されています。視野を広げてみましょう。',
                            },
                            ResultReserve: {
                                '過去':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '現在':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                                '未来':'うまくいかなかったことを嘆いていましたが、あなたは別の可能性があることに気づくことができるでしょう。',
                            },
                    },
                    {
                            no: 406,
                            imgUrl: require('@/assets/tarotcard_c6.png'),
                            name: {
                                normal: 'カップ6',
                                reserve: 'カップ6（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '現在':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                                '未来':'あなたは過去の良かったことを思い返しています。それはあるがままの現実というより美化された思い出でしょう。もしくは過去出会った人から好意をもらうことも暗示しています。',
                            },
                            ResultReserve: {
                                '過去':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '現在':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                                '未来':'昔良かった過去に執着することをやめて、未来に向かって歩むことになるかもしれません。',
                            },
                    },
                    {
                            no: 407,
                            imgUrl: require('@/assets/tarotcard_c7.png'),
                            name: {
                                normal: 'カップ7',
                                reserve: 'カップ7（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '現在':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                                '未来':'あなたはあれこれとたくさんの可能性を想像しているでしょう。ただ、それはあまり現実的なことではなく理想や夢ばかりを追って地に足がついていない状況かもしれません。',
                            },
                            ResultReserve: {
                                '過去':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '現在':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                                '未来':'理想ばかりを追っていたところから、現実的なアイディアとして着地することができそうです。',
                            },
                    },
                    {
                            no: 408,
                            imgUrl: require('@/assets/tarotcard_c8.png'),
                            name: {
                                normal: 'カップ8',
                                reserve: 'カップ8（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '現在':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                                '未来':'これまで積み上げてきたものを途中で諦めて、あなたは別の道へ旅立つでしょう。目の前のことより、すでに別のものへ情熱が移っているようです。',
                            },
                            ResultReserve: {
                                '過去':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '現在':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                                '未来':'もう一度再チャレンジする機会が訪れそうです。まだ諦めたくないのなら挑戦するのも良いでしょう。',
                            },
                    },
                    {
                            no: 409,
                            imgUrl: require('@/assets/tarotcard_c9.png'),
                            name: {
                                normal: 'カップ9',
                                reserve: 'カップ9（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。ただし慢心は禁物です。',
                                '現在':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                                '未来':'あなたの望んだことや理想が実現し心から満足できる状況になりそうです。堂々と胸を張れるような出来事が訪れるでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '現在':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                                '未来':'あなたの願いは実現しても満足しきれない結果になるか、もしくは慢心が仇となりそうです。自慢したり傲慢な対応には気をつけましょう。',
                            },
                    },
                    {
                            no: 410,
                            imgUrl: require('@/assets/tarotcard_c10.png'),
                            name: {
                                normal: 'カップ10',
                                reserve: 'カップ10（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '現在':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                                '未来':'心から喜べるような幸せな出来事が訪れそうです。それはただの自己満足ではなく周りの人や家族、みんなが幸せになるでしょう。これはあくまでも物質的な成功ではなく精神的な満足となります。',
                            },
                            ResultReserve: {
                                '過去':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '現在':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                                '未来':'家族や周りの人があまり喜べない出来事が訪れそうです。自分のことだけでなく周りの幸せを考えてあげると良いかもしれません。',
                            },
                    },
                    {
                            no: 411,
                            imgUrl: require('@/assets/tarotcard_c11.png'),
                            name: {
                                normal: 'カップペイジ',
                                reserve: 'カップペイジ（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '現在':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                                '未来':'あなたのユニークな才能やキャラクターがみんなから愛されたり、純粋な想いを優先すると良い結果を生みそうです。芸術的な創作を始めるのも良いでしょう。',
                            },
                            ResultReserve: {
                                '過去':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '現在':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                                '未来':'あなたの幼稚な性格が受け入れられなかったり、自分が興味のないことを押し付けられてしまうかもしれません。',
                            },
                    },
                    {
                            no: 412,
                            imgUrl: require('@/assets/tarotcard_c12.png'),
                            name: {
                                normal: 'カップナイト',
                                reserve: 'カップナイト（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '現在':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                                '未来':'あなたの理想を叶えてくれる人が現れるかもしれません。もしくは、あなた自身が夢や理想に向かって歩み始めていくでしょう。',
                            },
                            ResultReserve: {
                                '過去':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '現在':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                                '未来':'夢や理想を語るだけで実際は中身が伴っていなかったり、嘘つきの可能性があるので甘い話には注意してください。もしくは自分がそのような人になっていることもあります。',
                            },
                    },
                    {
                            no: 413,
                            imgUrl: require('@/assets/tarotcard_c13.png'),
                            name: {
                                normal: 'カップクイーン',
                                reserve: 'カップクイーン（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '現在':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                                '未来':'相手の想いを受け取り優しく包み込めるでしょう。相手への思いやりが周りを幸せにしていきます。',
                            },
                            ResultReserve: {
                                '過去':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '現在':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                                '未来':'相手の頼みを断れなかったりすることで自分自身の感情が大きく揺れてしまいそうです。なんでも安請け合いはやめた方が良いでしょう。大変な時はNOという勇気を。',
                            },
                    },
                    {
                            no: 414,
                            imgUrl: require('@/assets/tarotcard_c14.png'),
                            name: {
                                normal: 'カップキング',
                                reserve: 'カップキング（逆位置）'
                            },
                            ResultNormal: {
                                '過去':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '現在':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                                '未来':'懐が深く優しい男性からの援助が期待できます。悩みがあればなんでも受け止めてくれそうです。もしくは自分自身がそのように精神的な余裕を持っている状況になっているでしょう。',
                            },
                            ResultReserve: {
                                '過去':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '現在':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                                '未来':'自分の感情だけで相手に言いがかりをつけてしまったり周りを振り回してしまいそう。もし自分がパワーバランスが強い場合は節度を持った対応を心がけ、相手が強い場合は今は深く拘らず距離を置いた方が良いでしょう。',
                            },
                    },
                ],
            }
        },
        methods:{
            select: function(e){
                this.theme = e.target.value;
                if ( this.theme !== "" ){
                    this.cardDisabled = false;
                    this.snacktext = "カードをタップしてください";
                    return false;
                }
                else ( this.theme === "" ); {
                    this.cardDisabled = true;
                    this.snacktext = "テーマを選択してください";
                    return true;
                }
            },
            reset: function () {
                this.$router.go({path: this.$router.currentRoute.path, force: true})
            },
            change:function(){
                this.isActive = !this.isActive;
                this.PopUpShow = !this.PopUpShow;
            },
            change1:function(){
                this.CardIsActive1 = !this.CardIsActive1;
            },
            change2:function(){
                this.CardIsActive2 = !this.CardIsActive2;
            },
            change3:function(){
                this.CardIsActive3 = !this.CardIsActive3;
            },
            btnActive: function(){
                const n = this.turn;
                this.btnShow;
                if ( n == 2 ) {
                    this.isDisabled = !this.isDisabled;
                    this.SnackIsActive = !this.SnackIsActive;
                    this.btnShow = !this.btnShow;
                    return true;
                }
                else if ( 0 == n  ){
                    this.selectDisabled = !this.selectDisabled;
                    this.turn++;
                    return true;
                }
                else {
                    this.turn++;
                    return false;
                }
            },
            ramdam1: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl1 = this.imgsUrl1;
                this.imgsUrl1 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve1 = this.cardReserve1;
                if ( n == 1 ){
                    this.cardReserve1 = 'transform:rotate(0deg)';
                    this.cardText1 = this.imgsCard[imageNo]['ResultNormal']['過去'];
                    this.cardName1 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve1 = 'transform:rotate(180deg)';
                    this.cardText1 = this.imgsCard[imageNo]['ResultReserve']['過去'];
                    this.cardName1 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam2: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl2 = this.imgsUrl2;
                this.imgsUrl2 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve2 = this.cardReserve2;
                if ( n == 1 ){
                    this.cardReserve2 = 'transform:rotate(0deg)';
                    this.cardText2 = this.imgsCard[imageNo]['ResultNormal']['現在'];
                    this.cardName2 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve2 = 'transform:rotate(180deg)';
                    this.cardText2 = this.imgsCard[imageNo]['ResultReserve']['現在'];
                    this.cardName2 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
            ramdam3: function(e) {
                const imageNo = Math.floor( Math.random() * this.imgsCard.length);
                e.target.imgsUrl3 = this.imgsUrl3;
                this.imgsUrl3 = this.imgsCard[imageNo]['imgUrl'];
                const n = Math.floor( Math.random() * 2 );
                e.target.cardReserve3 = this.cardReserve3;
                if ( n == 1 ){
                    this.cardReserve3 = 'transform:rotate(0deg)';
                    this.cardText3 = this.imgsCard[imageNo]['ResultNormal']['未来'];
                    this.cardName3 = this.imgsCard[imageNo]['name']['normal'];
                }
                else {
                    this.cardReserve3 = 'transform:rotate(180deg)';
                    this.cardText3 = this.imgsCard[imageNo]['ResultReserve']['未来'];
                    this.cardName3 = this.imgsCard[imageNo]['name']['reserve'];
                }
                delete this.imgsCard[imageNo];
                this.imgsCard = this.imgsCard.filter(Boolean);
            },
        }
    }  
</script>

<style scoped>
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
    button{
        background-color: transparent;
    }
    .page-title {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 1.7;
        background: #ffffff73;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 12px;
        max-width: 600px;
        margin: 0 auto;
    }
  .divination {
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
  .cardchoice-area {
    margin-bottom: 20px;
  }
.card-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    height: 20rem;
    width: 20rem;
    margin: 20px auto 60px;
    align-items: center;
}
.card-list li {
    margin: 4px;
}
.card-list img{
    width: 80px;
    height: 140px;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}
    .card-list p {
        font-size: 12px;
        line-height: 1.5;
    }
    .result-title {
        font-size: 16px;
        padding-bottom: 1em;
        border-bottom: 1px solid #eee;
    }
    .card-result {
        max-width: 300px;
        margin: 32px auto;
    }
    .card-result dl {
        background: #fff;
        border-radius: 16px;
        max-width: 400px;
        margin: 0 auto 40px;
    }
    .card-result dl dt {
        background: #F4F4F4;
        border-radius: 10px;
        font-weight: bold;
        line-height: 1.7;
        letter-spacing: 2px;
        padding: 6px;
        max-width: 300px;
        margin: 0 auto 20px;
    }
    .card-result__img-area {
        margin-bottom: 16px;
    }
    .card-result__card-name {
        font-weight: bold;
        margin-bottom: 1em;
    }
    .card-result dl dd img {
        max-width: 100px;
    }
    @media screen and (min-width:769px) {
        .card-list img {

        }
    }
.mb-1em{
  margin-bottom: 1em;
}
.normal-txt {
    font-size: 14px;
    text-align: left;
}
.resultshow1,
.resultshow2,
.resultshow3,
.resultshow4,
.resultshow5,
.resultshow6,
.resultshow7,
.resultshow8,
.resultshow9,
.resultshow10,
.resultshow11,
.resultshow12,
.resultshow13 {
    display: block;
}

.carddisabled {
    background: none !important; 
}
.carddisabled img{
    filter: none;
    opacity: 0.5;
    pointer-events: none;
}

a,
a:visited{
    color: #fff;
}
button:disabled{
    background: #D3D3D3;
}
button:disabled a {
    color: #838383;
}
button a {
    display: inline-block;
    width: 100%;
}

.popup-under {
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
    border-radius: 6px 6px 0 0;
    box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.17);
    box-sizing: border-box;
    padding: 20px;
}
.snackshow {
    display: none;
}
.resultshow {
  display: block;
}
.btnshow {
    display: none;
}
.popupshow {
    bottom: -300px;
}
</style>


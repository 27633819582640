<template>
  <ul class="header-list">
    <li class="header-list__item">
      <router-link to="/divination" :class="{ 'active': $route.path === '/divination' }">
        <img src="../assets/ico-card-today.svg">
        <div class="header-list__item__menu">今日の運勢</div>
      </router-link>
    </li>
    <li class="header-list__item">
      <router-link to="/divination-free" :class="{ 'active': $route.path === '/divination-free' }">
        <img src="../assets/ico-card-free.svg">
        <div class="header-list__item__menu">フリースタイル</div>
      </router-link>
    </li>
    <li class="header-list__item">
      <router-link to="/divination-three" :class="{ 'active': $route.path === '/divination-three' }">
        <img src="../assets/ico-card-three.svg">
        <div class="header-list__item__menu">スリーカード</div>
      </router-link>
    </li>
  </ul>
</template>

<script setup>
import { useRoute } from 'vue-router';

const $route = useRoute();
</script>

<style scoped>
a {
  color: #363636;
  text-decoration: none;
}

.header-list {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 6px;
  gap: 12px;
  justify-content: space-around;
  border-bottom: 1px solid #E9E9E9;
}

.header-list a {
  text-align: center;
  align-items: center;
}
.header-list a img {
  width: 24px;
  transition: filter 0.3s ease-in-out; /* スムーズな色変化 */
}
.header-list__item {
  font-size: 11px;
  letter-spacing: 1px;
}
.header-list__item__menu {
  line-height: 1;
  color: #838383;
}

/* アクティブなタブのスタイル */
.header-list a.active .header-list__item__menu {
  color: #ae50f8; /* 選択時の文字色 */
  font-weight: bold;
}

/* SVG の色を紫 (#ae50f8) に変更 */
.header-list a.active img {
  filter: brightness(0) saturate(96%) invert(63%) sepia(47%) saturate(386%) hue-rotate(245deg) brightness(68%) contrast(93%);
}
</style>
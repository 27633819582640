import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import DivinationToday from '@/views/DivinationToday.vue'
import DivinationThree from '@/views/DivinationThree.vue'
import DivinationFree from '@/views/DivinationFree.vue'
import Subscription from '@/views/Subscription.vue'
import LogIn from '@/views/LogIn.vue'
//import Chatroom from '@/views/Chatroom'
import UserHome from '@/views/UserHome.vue'
import UserSubscription from '@/views/UserSubscription.vue'
import useValidate from '../auth/validate'
import Payment from '@/components/Payment.vue'

const { error, validate } = useValidate()

const requireAuth = async (to, from, next) => {
  const uid = window.localStorage.getItem('uid')
  const client = window.localStorage.getItem('client')
  const accessToken = window.localStorage.getItem('access-token')

  if (!uid || !client || !accessToken) {
    console.log('ログインしていません')
    next({ name: 'LogIn' })
    return
  }

  await validate()

  if (error.value) {
    console.log('認証に失敗しました')
    next({ name: 'LogIn' })
  } else {
    next()
  }

}

const noRequireAuth = async (to, from, next) => {
  const uid = window.localStorage.getItem('uid')
  const client = window.localStorage.getItem('client')
  const accessToken = window.localStorage.getItem('access-token')

  if (!uid && !client && !accessToken) {
    next()
    return
  }

  await validate()

  if (!error.value) {
    next({ name: 'UserHome' })
  } else {
    next()
  }
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/divination',
    name: 'DivinationToday',
    component: DivinationToday
  },
  {
    path: '/divination-three',
    name: 'DivinationThree',
    component: DivinationThree
  },
  {
    path: '/divination-free',
    name: 'DivinationFree',
    component: DivinationFree
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn,
    beforeEnter: noRequireAuth
  },
  //{
  //  path: '/chatroom',
  //  name: 'Chatroom',
  //  component: Chatroom,
  //  beforeEnter: requireAuth
  //},
  {
    path: '/user/home',
    name: 'UserHome',
    component: UserHome,
    beforeEnter: requireAuth
  },
  {
    path: '/user/subscription',
    name: 'UserSubscription',
    component: UserSubscription,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment  // 支払いページへのルート
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Google Analyticsのページ遷移トラッキング
router.afterEach((to) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', 'G-F6NCQWNRKD', {
      page_path: to.fullPath,
    });
  }
});

export default router